import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CreateCaseModalComponent } from './components/create-case-modal/create-case-modal.component';
import { CommonModule } from '@angular/common';
import { OpenTextModalComponent } from './components/open-text-modal/open-text-modal.component';
import { IndianCurrencyPipe } from './pipes/indian-currency.pipe';
import { InrFormatDirective } from './directives/inr-format.directive';
import { TaxCreateCaseModalComponent } from './components/tax-create-case-modal/tax-create-case-modal.component';
import { InsightLoaderComponent } from './components/insight-loader/insight-loader.component';
import { InrMetricPipe } from './pipes/inr-metric.pipe';
import { BlurZeroPipe } from './pipes/blur-zero.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { UtilityService } from './services/utility.service';

@NgModule({
  declarations: [
    CreateCaseModalComponent,
    TaxCreateCaseModalComponent,
    OpenTextModalComponent,
    IndianCurrencyPipe,
    InrFormatDirective,
    InsightLoaderComponent,
    FormatNumberPipe,
    InrMetricPipe,
    BlurZeroPipe,
    DeleteModalComponent,
  ],
  imports: [
    CommonModule,
    CalendarModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    DropdownModule
  ],
  exports: [
    CalendarModule,
    ToastrModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    DropdownModule,
    CreateCaseModalComponent,
    TaxCreateCaseModalComponent,
    IndianCurrencyPipe,
    InrFormatDirective,
    InsightLoaderComponent,
    FormatNumberPipe,
    InrMetricPipe,
    BlurZeroPipe,
    InsightLoaderComponent
  ],
  providers: [
    UtilityService
  ]
})
export class SharedModule { }
