<div class="modal-header">
    <h4 class="modal-title">Please select whether you want to create</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body m-10">
    <form #myForm="ngForm" novalidate>
        <div class="custom-control custom-radio">
            <input id="new" type="radio" class="custom-control-input" value="new" name="case" ngModel required>
            <label class="custom-control-label" for="new">A New Case</label>
        </div>
        <!-- AT -->
        <div class="custom-control custom-radio">

            <input id="penalty-case" type="radio" class="custom-control-input" value="penalty-case" name="case" ngModel
                required>
            <label class="custom-control-label" for="penalty-case">A New Penalty Case</label>
        </div>

        <!-- HC -->
        <div class="custom-control custom-radio"
            *ngIf="!(selectedTab ==='tab1') && !(selectedTab ==='tab2') && !(selectedTab ==='tab3') && !(selectedTab === 'tab4')">

            <input id="existing-case" type="radio" class="custom-control-input" value="existing-case" name="case"
                ngModel required>
            <label class="custom-control-label" for="existing-case">Departmental Appeal For an Existing Case</label>
        </div>

        <div class="custom-control custom-radio"
            *ngIf="!(selectedTab ==='tab1') && !(selectedTab ==='tab2') && !(selectedTab ==='tab3') && !(selectedTab === 'tab4')">

            <input id="Appealpenalty-Case" type="radio" class="custom-control-input" value="Appealpenalty-Case"
                name="case" ngModel required>
            <label class="custom-control-label" for="Appealpenalty-Case">Departmental Appeal For Penalty Case</label>
        </div>
        <div class="custom-control custom-radio"
            *ngIf="!(selectedTab ==='tab1') && !(selectedTab ==='tab2') && !(selectedTab ==='tab3') && !(selectedTab ==='tab4') && !(selectedTab === 'tab5') && !(selectedTab ==='tab7')">

            <input id="WritTax-Case" type="radio" class="custom-control-input" value="WritTax-Case" name="case" ngModel
                required>
            <label class="custom-control-label" for="WritTax-Case">A New Writ Case</label>
        </div>
        <br>
        <div class="submit-footer">
            <button type="submit" class="btn btn-outline-primary mr-1 " [disabled]="!myForm.value.case"
                (click)="submitForm(myForm)">Start</button>
        </div>
    </form>
</div>