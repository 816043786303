import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(
    private http: HttpClient
  ) { }

  saveFtp(sftpVal: boolean) {
    return this.http.get(`${environment.apiUrl}/mgmt/company/garnetAdmin?isAdmin=${sftpVal}`).pipe(map((resp) => {
      return resp;
    }));
  }

  fetchUserList(cmpId: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/company/users?companyid=${cmpId}`).pipe(map((resp) => {
      return resp;
    }));
  }
  fetchRoleList() {
    return this.http.get(`${environment.apiUrl}/mgmt/user/roles`).pipe(map((resp) => {
      return resp;
    }));
  }
  fetchSftp() {
    return this.http.get(`${environment.apiUrl}/mgmt/company/isGarnetAdmin`).pipe(map((resp) => {
      return resp;
    }));
  }
  changeUserRole(roleID: any, userid: any, viewHierarchy_id: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/company/users?userid=${userid}&roleid=${roleID}&companyId=${viewHierarchy_id}`, {}).pipe(map((resp) => {
      return resp;
    }));
  }
  cacheEvict() {
    return this.http.delete(`${environment.apiUrl}/lms2/common/evict-cache`).pipe(map((resp) => {
      return resp;
    }))
  }
  bulkPrintAllow(pan: any, data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/company/business/metadata?companyIdentifier=${pan}`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  getRegNo(data: any) {
    return this.http.post(`${environment.baseUrl}/wl/get/regsinfo`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  getCaseId(data: any) {
    return this.http.post(`${environment.baseUrl}/wl/get/caseidinfo`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  deleteUserRole(userid: any, viewHierarchy_id: any) {
    return this.http.delete(`${environment.apiUrl}/mgmt/company/user/role?userid=${userid}&companyid=${viewHierarchy_id}`).pipe(map((resp) => {
      return resp;
    }));
  }
  inviteUser(data: any) {
    return this.http.put(`${environment.apiUrl}/mgmt/company/inviteuser`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  fetchWatchListFormData(userMailId: any, cmpId: any) {
    return this.http.get(`${environment.baseUrl}/wl/get?userMailId=${userMailId}&companyId=${cmpId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  divisionWatchList(data: any) {
    return this.http.get(`${environment.baseUrl}/wl/get/divinfo?moduleName=${data.moduleName}&companyId=${data.companyId}`).pipe(map((resp) => {
      return resp;
    }))
  }
  actTypeWatchList(data: any) {
    return this.http.get(`${environment.baseUrl}/wl/get/actinfo?moduleName=${data.moduleName}&companyId=${data.companyId}`).pipe(map((resp) => {
      return resp;
    }))
  }

  createWatchList(data: any) {
    return this.http.post(`${environment.baseUrl}/wl/create`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  fetchAllWatchList(data: any) {
    return this.http.get(`${environment.baseUrl}/wl/all?companyId=${data.companyId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  deleteWatchList(email: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: email
    };

    return this.http.delete(`${environment.baseUrl}/wl/delete`, options).pipe(map((resp) => {
      return resp;
    }));

  }

  updateWatchList(data: any) {
    if (!data.auditwl.regNos || data.auditwl.regNos === '') data.auditwl.regNos = [];
    if (!data.auditwl.caseIds || data.auditwl.caseIds === '') data.auditwl.caseIds = [];
    if (!data.gstwl.regNos || data.gstwl.regNos === '') data.gstwl.regNos = [];
    if (!data.gstwl.caseIds || data.gstwl.caseIds === '') data.gstwl.caseIds = [];
    if (!data.pregstwl.regNos || data.pregstwl.regNos === '') data.pregstwl.regNos = [];
    if (!data.pregstwl.caseIds || data.pregstwl.caseIds === '') data.pregstwl.caseIds = [];
    if (!data.dtwl.caseIds || data.dtwl.caseIds === '') data.dtwl.caseIds = [];
    return this.http.post(`${environment.baseUrl}/wl/update`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  fetchWatchListById(email: any, companyId: any) {
    return this.http.get(`${environment.baseUrl}/wl/get?userMailId=${email}&companyId=${companyId}`).pipe(map((resp) => {
      return resp;
    }));

  }
  getPANList(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/user/company/assignedLegalPansByUniqueCmpId?uniqueCompanyId=${data.uniqueCompanyId}&userMailId=${data.userMailId}`).pipe(map((resp) => {
      return resp;
    }));
  }
  rootCompanyId: number = Number(sessionStorage.getItem('companyId'));
  getCompanyId() {
    const navContext = JSON.parse(sessionStorage.getItem('navContext') ?? '{}');
    return navContext?.companyId ?? this.rootCompanyId;
  }
  getActivityLog(data: any) {
    const payload = {
      ...data,
      companyId: this.getCompanyId(),
      sortdir: 'DESC',
      sortfield: 'time',
    }
    return this.http.post(`${environment.apiUrl}/lms2/activitylog/getlogs`, payload).pipe(map((resp) => {
      return resp;
    }));
  }
}
