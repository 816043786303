<div class="modal-header">
    <h4 class="modal-title">
        <p style="color: red;">Warning!</p> - The case once deleted it will get deleted permanently
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body m-10">
    <form #myForm="ngForm" novalidate>
        <div class="custom-control custom-radio">
            <input id="current-forum" type="radio" class="custom-control-input" value="current-forum" name="case"
                ngModel required>
            <label class="custom-control-label" for="current-forum">Do you want to delete the case from Current
                Forum?</label>
        </div>
        <!-- AT -->
        <div class="custom-control custom-radio">
            <input id="entire-case" type="radio" class="custom-control-input" value="entire-case" name="case" ngModel
                required>
            <label class="custom-control-label" for="entire-case">Do you want to delete the entire case?</label>
        </div>
        <br>
        <div class="submit-footer">
            <button type="submit" class="btn btn-outline-primary mr-1 " [disabled]="!myForm.value.case"
                (click)="submitForm(myForm)">Yes</button>

            <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                (click)="activeModal.dismiss('Cross click')">NO</button>
        </div>
        <!-- <div class="submit-footer">
           
        </div> -->
    </form>
</div>