<div class="modal-header">
    <h4 class="modal-title">Please select whether you want to create</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body m-10">
    <form #myForm="ngForm" novalidate  *ngIf="!isPreLabel">
        <!-- <div class="custom-control custom-radio" *ngIf="(caseType === 'scn')"  > 
            <input id="smart-Upload" type="radio" class="custom-control-input" value="smart-Upload" name="case" ngModel required>
            <label class="custom-control-label" for="smart-Upload">New Case - Auto case creation with PDF</label>
        </div> -->
        <div class="custom-control custom-radio" *ngIf="!(caseType === 'apt') && !(caseType ==='hc') && !(caseType ==='sc')"  > 
            <input id="new" type="radio" class="custom-control-input" value="new" name="case" ngModel required>
            <label class="custom-control-label" for="new">New Case</label>
        </div>
         <div class="custom-control custom-radio"  *ngIf="!(caseType ==='scn') && !(caseType === 'apt') && !(caseType ==='hc') && !(caseType ==='sc')"> 
            <input id="remanded" type="radio" class="custom-control-input" value="remanded" name="case" ngModel
                required>
            <label class="custom-control-label" for="remanded">A Remanded Back Case</label>
        </div> 
           <!-- AT -->
         <div class="custom-control custom-radio" *ngIf="!(caseType ==='scn') && !(caseType ==='aja') && !(caseType ==='apa')">
            
            <input id="pre-Appellate" type="radio" class="custom-control-input" value="pre-Appellate" name="case" ngModel
                required>
            <label class="custom-control-label" for="pre-Appellate">Departmental Appeal for an existing case where the assessee has also filed an appeal</label>
        </div> 
        <!-- HC -->
        <div class="custom-control custom-radio"  *ngIf="!(caseType ==='scn') && !(caseType ==='aja') && !(caseType ==='apa') && !(caseType === 'apt') && !(caseType ==='sc') " >
            
            <input id="pre-HC" type="radio" class="custom-control-input" value="pre-HC" name="case" ngModel
                required>
            <label class="custom-control-label" for="pre-HC">A direct writ Petition filed for challenging any legal provision / Circular / Notification</label>
        </div> 
        <br>
        <div class="submit-footer">
            <button type="submit" class="btn btn-outline-primary mr-1 " [disabled]="!myForm.value.case"
                (click)="submitForm(myForm)">Start</button>
        </div>
    </form>

    <form #myForm="ngForm" novalidate *ngIf="isPreLabel">
        <div class="custom-control custom-radio" *ngIf="!(caseType === 'preapt') && !(caseType ==='prehc') && !(caseType ==='presc')"  > 
            <input id="new" type="radio" class="custom-control-input" value="new" name="case" ngModel required>
            <label class="custom-control-label" for="new">A New Case</label>
        </div>
         <div class="custom-control custom-radio"  *ngIf="!(caseType === 'preapt') && !(caseType ==='prehc') && !(caseType ==='presc')"> 
            <input id="remanded" type="radio" class="custom-control-input" value="remanded" name="case" ngModel
                required>
            <label class="custom-control-label" for="remanded">A Remanded Back Case</label>
        </div> 
           <!-- AT -->
         <div class="custom-control custom-radio" *ngIf="!(caseType ==='preaja') && !(caseType ==='preapa')">
            
            <input id="pre-Appellate" type="radio" class="custom-control-input" value="pre-Appellate" name="case" ngModel
                required>
            <label class="custom-control-label" for="pre-Appellate">Departmental Appeal for an existing case where the assessee has also filed an appeal</label>
        </div> 
        <!-- preHC -->
        <div class="custom-control custom-radio"  *ngIf="!(caseType ==='preaja') && !(caseType ==='preapa') && !(caseType === 'preapt') && !(caseType ==='presc') " >
            
            <input id="pre-HC" type="radio" class="custom-control-input" value="pre-HC" name="case" ngModel
                required>
            <label class="custom-control-label" for="pre-HC">A direct writ Petition filed for challenging any legal provision / Circular / Notification</label>
        </div> 
        <br>
        <div class="submit-footer">
            <button type="submit" class="btn btn-outline-primary mr-1 " [disabled]="!myForm.value.case"
                (click)="submitForm(myForm)">Start</button>
        </div>
    </form>
</div>
