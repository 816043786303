import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blurzero'
})
export class BlurZeroPipe implements PipeTransform {
  transform(value: string | number): string | number {
    if (String(value) === '0.00') {
      return `<span class="blurzero">0.00</span>`;
    }
    
    if (String(value) !== '0' || Number(value) !== 0) return value;

    return `<span class="blurzero">0</span>`;
  }
}
