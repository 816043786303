import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tax-create-case-modal',
  templateUrl: './tax-create-case-modal.component.html',
  styleUrls: ['./tax-create-case-modal.component.css']
})
export class TaxCreateCaseModalComponent implements OnInit {

  isSubmitted = false;
  selectedTab: any;
  @Input() set tab(value: string) {
    this.selectedTab = value;
  }

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  submitForm(form: NgForm) {
    this.isSubmitted = true;
    if (form.value.case) {
      this.activeModal.dismiss(form.value.case);
      return;
    }
  }

}
