import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[InrFormat]'
})
export class InrFormatDirective {

  constructor(private el: ElementRef) { }
  @HostListener('input', ['$event.target.value', '$event.target.selectionStart'])
  onInput(value: string, selectionStart: number) {
    const formattedValue = this.formatCurrency(value);
    this.el.nativeElement.value = formattedValue;
    const diff = formattedValue.length - value.length;
    const newPosition = selectionStart + diff;
    this.el.nativeElement.setSelectionRange(newPosition, newPosition);

  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';
    const formattedValue = this.formatCurrency(pastedText);
    document.execCommand('insertText', false, formattedValue);
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const input = event.key;
    const isDigit = /^\d$/.test(input);
    const isBackspace = input === 'Backspace' || input === 'Delete' || input === '-';

    if (!isDigit && !isBackspace) {
      event.preventDefault();
    }
  }

  formatCurrency(value: string): string {
    const numericValue = parseFloat(value.replace(/,/g, ''));
    if (!isNaN(numericValue)) {
      const roundedValue = Math.round(numericValue);
      return new Intl.NumberFormat('en-IN').format(roundedValue);
    }
    return value;
  }

}
