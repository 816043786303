import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-open-text-modal',
  templateUrl: './open-text-modal.component.html',
  styleUrls: ['./open-text-modal.component.css']
})
export class OpenTextModalComponent implements OnInit {

  fieldLabel: any;
  fieldId:any;
  @Input() set label (value: string){
      this.fieldLabel = value;
  }
  @Input() set id (value: boolean){
    this.fieldId = value;
  }

  @Input() reasonValue:any

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {  }

  submitForm(){
    this.activeModal.dismiss({reason: this.reasonValue , id : this.fieldId});
  }
}
