import { Pipe, PipeTransform } from '@angular/core';

const metricMap = { thousand: 1000, lakh: 100000, crore: 10000000 };
const abbrevMap = { thousand: 'K', lakh: 'L', crore: 'Cr' };

@Pipe({
  name: 'inrMetric'
})
export class InrMetricPipe implements PipeTransform {
  transform(value: number, metric: 'thousand' | 'lakh' | 'crore'): string {
    return (Math.round((value / metricMap[metric]) * 100) / 100).toFixed(2);
  }
}
