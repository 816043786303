<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="submitForm()"></button>
</div>
<div class="modal-body m-10">
    <form novalidate >
        <label class="" for="reason">{{fieldLabel}}</label>
        <textarea id="reason" type="text" class="form-control height-cls" name="reason" [(ngModel)]="reasonValue"></textarea>
    </form>
    <div class="submit-footer mt-2 text-right">
        <button type="submit" class="btn btn-outline-primary mr-1 "
            (click)="submitForm()">Save</button> 
    </div>
</div>
