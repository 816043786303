import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'INR',
})
export class IndianCurrencyPipe implements PipeTransform {
  transform(value: any, limit: any): any {
    const precision = (limit < 0) ? 2 : limit;
    let output = ''
    if (typeof value === 'string') {
      value = Number(value);
    }

    if (value) {
      const result = value.toFixed(precision).toString().split('.');

      let lastThree = result[0].substring(result[0].length - 3);
      const otherNumbers = result[0].substring(0, result[0].length - 3);
      if (otherNumbers != '') lastThree = ',' + lastThree;
      output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

      if (result.length > 1) {
        output += '.' + result[1];
      }
    }

    return output;
  }
}
