import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, refCount, publishReplay } from 'rxjs/operators';
import { Observable, ReplaySubject, from, BehaviorSubject, Subject } from 'rxjs';
import { TokenService } from '../../shared/services/token.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  initialLoginValue = this.isAuthenticated() ? 2 : this.isLoginAuthenticated() ? 1 : 0;
  loginIndicator = new BehaviorSubject(this.initialLoginValue); // 0: Not logged in , 1: Init Login Done, 2: Fully Logged In
  checkChangePass$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  getLoginIndicator(): Observable<any> {
    return this.loginIndicator.asObservable();
  }
  getLoginEmail(): Observable<any> {
    return this.checkChangePass$.asObservable();
  }

  setLoginIndicator(data: number): boolean {
    this.loginIndicator.next(data);
    return true;
  }
  setLoginEmail(data: any) {
   return this.checkChangePass$.next(data);
  }

  login(body:any) {
    return this.http.post(`${environment.apiUrl}/mgmt/login`, body).pipe(map((response) => {
      return response
    }));
  }

  resetPassword(data:any) {
    return this.http.post(`${environment.apiUrl}/mgmt/user/forgotpassword`, data).pipe(map((response) => {
      return response
    }));
  }

  doResetPassword(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/user/resetpassword`, data).pipe(map((response) => {
      return response
    }));
  }

  isAuthenticated(): boolean {
    const token = sessionStorage.getItem('jwtToken');
    return !this.tokenService.isTokenExpired(token);
  }

  isLoginAuthenticated(): boolean {
    const token = sessionStorage.getItem('logintoken');
    return !this.tokenService.isTokenExpired(token);

  }
  
  
  tokenVerify(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/public/user/checktokenvalidity?`, {
      params: {
        token: data
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }
  tokenVerifyExternal(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/public/token/validate?`, {
      params: {
        token: data
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }
  comapnyList() {
    return this.http.get(`${environment.apiUrl}/mgmt/user/entity`).pipe(map((resp) => {
      return resp;
    }));
  }
  
}
