import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, registerLocaleData } from '@angular/common';
import localeIn from "@angular/common/locales/en-IN";
registerLocaleData(localeIn);

@Pipe({
  name: 'formatNum'
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: string | number): string {
    return formatCurrency(Number(value), 'en-IN', '', 'INR');
  }
}
