import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) {
  }
  isSubmitted = false;
  selectedTab: any;
  @Input() set tab(value: string) {
    this.selectedTab = value;
  }

  ngOnInit(): void {
  }

  submitForm(form: NgForm) {
    this.isSubmitted = true;
    if (form.value.case) {
      this.activeModal.dismiss(form.value.case);
      return;
    }
  }

}
