import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-insight-loader',
  templateUrl: './insight-loader.component.html',
  styleUrls: ['./insight-loader.component.css']
})
export class InsightLoaderComponent {

  @Input() width = '50px'

}
