import { Injectable } from '@angular/core';
import { UserRoleMap } from '../constant';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetterSetterService {

  userRole: any = UserRoleMap;
  // checkEntityType = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('navContext')));
  checkEntityType = new BehaviorSubject<any>(null);

  selectedBussiness = new BehaviorSubject<any>(null);

  constructor() { }

  set_UserRole(roleId?: string) {
    if (roleId) {
      let role = this.userRole[roleId];
      sessionStorage.setItem('role', role.role);
    }
  }
  get_UserRole() {
    return sessionStorage.getItem('role');

  }

  getSetCompany(company?: any) {
    if (company) {
      sessionStorage.setItem('company', company);
    } else {
      company = sessionStorage.getItem('company');
    }
    return company;
  }


  getSetCompanyId(companyid?: any) {
    if (companyid) {
      sessionStorage.setItem('companyId', companyid);
    } else {
      companyid = sessionStorage.getItem('companyId');
    }
    return companyid;
  }

  getSetOrgId(orgId?: any) {
    if (orgId) {
      sessionStorage.setItem('orgId', orgId);
    } else {
      orgId = sessionStorage.getItem('orgId');
    }
    return orgId;
  }

  getSetUser(user: any) {
    if (user) {
      sessionStorage.setItem('user', user);
    } else {
      user = sessionStorage.getItem('user');
    }
    return user;
  }

  getSetUserId(UserId?: any) {
    if (UserId) {
      sessionStorage.setItem('UserId', UserId);
    } else {
      UserId = sessionStorage.getItem('UserId');
    }
    return UserId;
  }

  getNavContextData() {
    return sessionStorage.getItem('navContext');
  }

  setProduct(product: any) {
    return sessionStorage.setItem('products', product);
  }

  getSetFilingGstin(gstinData: any) {
    if (gstinData) {
      sessionStorage.setItem('filingGstn', gstinData);
    } else {
      gstinData = sessionStorage.getItem('filingGstn');
    }
    return gstinData;
  }

  getSetfilingEntityName(data?: any) {
    if (data) {
      sessionStorage.setItem('filingEntityName', data);
    } else {
      data = sessionStorage.getItem('filingEntityName');
    }
    return data;
  }
  getAccessProduct(params: any) {
    return sessionStorage.getItem(params);
  }

  setAccessProduct(params: any, data?: any) {
    sessionStorage.setItem(params, data);
    return;
  }
  setViewHierarchyData(data: any) {
    sessionStorage.setItem('viewHierarchy_id', data.companyId);
    sessionStorage.setItem('viewHierarchy_entityType', data.entityType);
    sessionStorage.setItem('viewHierarchy_name', data.companyName);
  }
  getviewHierarchy_id() {
    return sessionStorage.getItem('viewHierarchy_id');
  }
  getviewHierarchy_name() {
    return sessionStorage.getItem('viewHierarchy_name');
  }
  setBusinessData(data: any) {
    this.checkEntityType.next(data);
  }
  getBusinessData() {
    return this.checkEntityType.asObservable();
  }

  getSetSelectedNoticePE(selectedNotice?: string) {
    if (selectedNotice) {
      sessionStorage.setItem('selectedNotice', selectedNotice);
    } else {
      selectedNotice = sessionStorage.getItem('selectedNotice')!;
    }
    return selectedNotice;
  }

  // getSetFilterData(data:any) {
  //   if (data) {
  //     sessionStorage.setItem('filterData', data);
  //   } else {
  //     data = sessionStorage.getItem('filterData');
  //   }
  //   return data;
  // }
}