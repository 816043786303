import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }


  getYears() {
    let yearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      yearData.push(financialYrRange);
      startYear++;
      loopyear--;
    }
    return yearData.reverse();
  }
}
