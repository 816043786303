import { Injectable } from '@angular/core';


import { GetterSetterService } from './getter-setter.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TokenService {

  constructor(
    private GetSet: GetterSetterService
  ) {

  }


  getter() {
    return sessionStorage.getItem('jwtToken');
  }

  setter(data: any) {
    sessionStorage.setItem('jwtToken', data.token);
    sessionStorage.setItem('logintoken', data.token);
    // sessionStorage.setItem('userId', data.email);
    this.GetSet.getSetUserId(data.email)
    this.GetSet.getSetCompanyId(data.companyid)
    //this.GetSet.getSetOrgId(data.orgId)
    return 1;
  }

  getTokenExpirationDate(token: any): Date {
    const decoded = JSON.parse(atob(token.split('.')[1]) ?? '{}');
    if (decoded['exp'] === undefined) { return null as any; }
    const date = new Date(0);
    date.setUTCSeconds(decoded['exp']);
    return date;
  }

  isTokenExpired(token?: any): boolean {
    if (!token) { token = this.getter(); }
    if (!token) { return true; }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }

  destroy() {
    sessionStorage.clear();
    return true;
  }

  getUumUrl() {
    return environment.uumUrl;
  }
}
